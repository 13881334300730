import React, { Component } from "react";
import "../page.css";
import JobsData from "../../../data/AdminJobs.json";
import { Link } from "gatsby";

class JobsComponent extends Component {
  render() {
    const data = JobsData;
    return (
      <div className="page">
        <section className="page-image-section">
          <div className="page-image-wrapper">
            <img className="page-image" alt={data.title} src={data.image} />
            <h1 className="page-title">{data.title}</h1>
            <p className="page-title" style={{ marginTop: "2rem" }}>
              {data.desc}
            </p>
          </div>
        </section>
        <div className="container">
          <section className="section">
            <h2 className="heading">{data.heading}</h2>
            <div className="headingLine" style={{ marginBottom: "2rem" }} />
            {data.openJobs.lenght > 1 ? (
              data.openJobs.map((data, index) => {
                return (
                  <div key={`jobs_item_${index}`} className="contentBoxWrapper">
                    <div className="contentBoxContent">
                      <h3>{data.title}</h3>
                      <p>{data.desc}</p>
                      <Link to={data.link} title={data.title}>
                        <button className="buttonBlack">Başvur</button>
                      </Link>
                    </div>
                  </div>
                );
              })
            ) : (
              <text>
                Açık pozisyonumuz bulunmamaktadır, detaylı bilgi için{" "}
                <a href="mailto:info@buyukyilmaz.av.tr" title="Açık Pozisyon">
                  {" "}
                  info@buyukyilmaz.av.tr
                </a>
                'ye e-posta gönderebilirsiniz.
              </text>
            )}
          </section>
        </div>
      </div>
    );
  }
}

export default JobsComponent;
