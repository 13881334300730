import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import Jobs from "../components/Jobs/Jobs";

class JobsPage extends Component {
  render() {
    return (
      <Layout>
        <div className="about-container">
          <Helmet title={`Kariyer | ${config.siteTitle}`} />
          <Jobs />
        </div>
      </Layout>
    );
  }
}

export default JobsPage;
